import Vue from 'vue'
import App from './App.vue'
import 'normalize.css/normalize.css'
import 'iview/dist/styles/iview.css'
import './styles/main.styl' // 公用样式须在 router 前导入
import { sync } from 'vuex-router-sync'
import store from './store'
import router from './router/index'
import './plugins/iview'
import wj from './plugins/wjtools'
import * as api from './api'


Vue.config.productionTip = false

sync(store, router)
Vue.prototype.$wj = wj
Vue.prototype.$api = api

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
